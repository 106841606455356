#footer {
    height: 20vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background: var(--clr-bg);

    #footer-content-left {
        width: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;

        .footer-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 2.5rem;
            height: 2.5rem;
            background-color: var(--clr-details);
            color: var(--clr-text);
            cursor: pointer;
            border-radius: 50%;
        }
    }

    #footer-content-right {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;

        .footer-credit{
            font-size: min(1.75vw, 1rem);
        }
    }
}

@media (max-width: 991px){
    #footer {
        height: 15vh;
    }
}

@media (max-width: 480px){
    #footer {
        height: 10vh;

        #footer-content-left {

            .footer-icon {
                width: 2rem;
                height: 2rem;
            }
        }
    }
}