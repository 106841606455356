#navbar {
    width: 100%;
    height: 20vh;
    display: flex;
    justify-content: space-between;
    align-items: center;
    top: 0;
    z-index: 1;

    #nav-left {
        width: 20%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: anotherDanger;

        #nav-logo-container {

            #nav-logo {
                text-align: center;
            }

            .nav-logo {
                width: 90%;
                padding: -50% 0 -50% 0;
            }

            .mobile-nav-home {
                width: 200%;
                padding-top: 8%;
                padding-left: 5%;
            }
        }
    }

    .mobile-nav {
        visibility: hidden;
        position: absolute;
        right: 5%;
        top: 50%;
        transform: translate(5%, -50%);
        width: 3rem;
        height: 3rem;
        padding: .25rem;
        background-color: var(--clr-bg);
        color: var(--clr-text);
    }

    #nav-right {
        position: relative;
        width: fit-content;
        height: 100%;
        padding: 0 1.6rem;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        text-align: center;
        font-size: min(3vw, 1.5rem);
        font-family: 'Roboto Condensed';

        .mobile-nav-close {
            position: absolute;
            top: 5%;
            right: 5%;
            background: #FF007A;
        }

        .mobile-nav-link {
            padding: 1.5rem;
        }
    }

    .nav-link {
        cursor: pointer;
    }


}

.absolute {
    position: absolute;
    border-bottom: none;
}

.relative {
    position: relative;
    border-bottom: 3px solid var(--clr-hover);
}

@media (max-width: 1157px){
    #navbar {

        #nav-right {
            font-size: min(3vw, 1.2rem);
        }
    }
}

@media (max-width: 991px){
    #navbar {
        height: 15vh;

        .mobile-nav {
            visibility: visible;
        }

        #nav-right {
            visibility: hidden;
            opacity: 0;
            position: absolute;
            right: 0;
            top: 15vh;
            height: 0;
            width: 50%;
            background: var(--clr-bg);
            border-left: 3px solid var(--clr-hover);
            border-bottom: 3px solid var(--clr-hover);
            flex-direction: column;
            z-index: 3;
        }

    }

    .absolute {
        position: relative;
        border-bottom: 3px solid var(--clr-hover);
    }

}

@media (max-width: 550px){
    #navbar {
        height: 10vh;

        #nav-right {
            top: 10vh;
        }

    }
}