#home-page {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;

    #home-img-container {
        position: relative;
        width: 100%;
        height: 100%;

        #home-img {
            width: 100%;
        }

        #slogan-img {
            position: absolute;
            width: 40%;
            top: 50%;
            right: 50%;
            transform: translate(50%,-50%);
        }
    }

    #home-content {
        position: relative;
        height: 40vh;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        text-align: center;
        align-items: center;
        background: var(--clr-bg);
        margin-top: 5vh;

        #home-title {
            font-size: min(1.5vw, 1.5rem);
        }

        #home-text {
            font-size: min(1.5vw, 1.5rem);
        }
    }
}

@media (max-width: 991px){
    #home-page {

        #home-content {
            width: 70%;

            #home-title {
                font-size: min(2.5vw, 1.5rem);
            }

            #home-text {
                font-size: min(2.5vw, 1.5rem);
            }
        }

    }
}

@media (max-width: 450px){
    #home-page {

        #home-img-container {
            margin-bottom: -40%;

            #slogan-img {
                width: 70%;
                top: 70%;
                right: 50%;
                transform: translate(50%,-70%);
            }
        }

        #home-content {
            height: 35vh;
            width: 100%;
            padding-top: 5vh;

            #home-title {
                font-size: min(3.5vw, 1.5rem);
                width: 70%;
            }

            #home-text {
                font-size: min(3.5vw, 1.5rem);
                width: 70%;
            }
        }

    }
}