* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    line-height: 1.6;
}

body, #root {
    width: 100%;
    height: 100%;
    color: var(--clr-text);
    background: var(--clr-bg);
    font-family: 'Roboto Condensed', sans-serif;
}

:root {
    --clr-text: #FFFFFF;
    --clr-q: #949494;
    --clr-bg: #181B1E;
    --clr-details: #FC8D68;
    --clr-hover: #FF007A;
    --clr-logoAccent: #D69785;
}

.page {
    position: relative;
    width: 100%;
    height: 100%;
}

a {
    text-decoration: none;
    color: var(--clr-text);
}