#listen-page-banner-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 22.5vw;
}

#listen-page {
    padding-bottom: 2rem;
    @media (max-width: 550px) {
        padding-bottom: 20vw;
    }
    @media (max-width: 400px) {
        padding-bottom: 35vw;
    }
    @media (max-width: 480px) {
        padding-bottom: 50vw;
    }
}

#listen-page-banner {
    width: 47.5%;
}