.modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    background: var(--clr-bg);
    transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out, transform 0.5s ease-in-out;
    visibility: hidden;
    transform: scale(0.95);
    opacity: 0;
    z-index: 2;

    #hidden {
        visibility: hidden;
    }

    .modal-img {
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 100%;
        display: block;
        line-height: 0;
        box-sizing: border-box;
        padding: 1rem;
        margin: 0 auto;
    }

    #modal-img-credit-container {
        width: 30%;
        display: flex;
        flex-direction: column;
        font-size: min(2vw, .75rem);
        justify-content: space-evenly;
        margin-right: 10%;

        .credit-link {
            color: var(--clr-hover)
        }
    }
}

.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}

#gallery-prev {
    position: fixed;
    top: 50%;
    left: 1rem;
    width: 3rem;
    height: 3rem;
    padding: .25rem;
    background-color: var(--clr-hover);
    color: var(--clr-text);
    cursor: pointer;
    transform: rotateY(180deg);
}

#gallery-close {
    position: fixed;
    top: 1rem;
    right: 1rem;
    width: 2rem;
    height: 2rem;
    padding: .25rem;
    background-color: var(--clr-details);
    color: var(--clr-text);
    cursor: pointer;
}

#gallery-next {
    position: fixed;
    top: 50%;
    right: 1rem;
    width: 3rem;
    height: 3rem;
    padding: .25rem;
    background-color: var(--clr-hover);
    color: var(--clr-text);
    cursor: pointer;
}

#gallery-page {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    padding: 1rem;

    .gallery-img {
        width: 100%;
        margin-bottom: 1rem;
        transition: all 350ms ease;
        -webkit-transition: all 350ms ease;
        cursor: pointer;
    }

    .gallery-img:hover {
        filter: opacity(.8);
        transform: scale(1.025);
    }
}

@media (max-width: 991px){

    #gallery-page {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }

    #gallery-prev {
        display: none;
    }

    #gallery-next {
        display: none;
    }
}

@media (max-width: 820px) {
    .modal {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        overflow-y: scroll;

        #modal-img-credit-container {
            width: auto;
            margin-right: 0;
            padding: 1rem 0 2rem 0;
        }
    }
}

@media (max-width: 480px){
    #gallery-page {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }
}
