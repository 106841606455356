@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital@1&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  line-height: 1.6;
}

body, #root {
  width: 100%;
  height: 100%;
  color: var(--clr-text);
  background: var(--clr-bg);
  font-family: "Roboto Condensed", sans-serif;
}

:root {
  --clr-text: #FFFFFF;
  --clr-q: #949494;
  --clr-bg: #181B1E;
  --clr-details: #FC8D68;
  --clr-hover: #FF007A;
  --clr-logoAccent: #D69785;
}

.page {
  position: relative;
  width: 100%;
  height: 100%;
}

a {
  text-decoration: none;
  color: var(--clr-text);
}

.member-bio-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 2% 0 2% 0;
}
.member-bio-container .member-img-container {
  position: relative;
  width: 40%;
  height: 40%;
  padding: 1rem;
}
.member-bio-container .member-img-container .member-img {
  width: 100%;
  height: 100%;
  filter: contrast(110%);
}
.member-bio-container .member-img-container .member-signature {
  width: 70%;
  position: absolute;
  left: 10%;
  bottom: 12.5%;
  opacity: 0;
  pointer-events: none;
  filter: contrast(150%);
  border-radius: 50%;
}
.member-bio-container .member-bio {
  position: relative;
  width: 45%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
}
.member-bio-container .member-bio .member-name {
  width: 90%;
  margin: -20% 0 -20% 0;
}
.member-bio-container .member-bio .member-text-container {
  height: 90%;
}
.member-bio-container .member-bio .member-text-container .member-text {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
}
.member-bio-container .member-bio .member-text-container .member-text .q {
  font-size: min(1.5vw, 1.25rem);
  color: var(--clr-q);
  padding: 2% 0 2% 0;
}
.member-bio-container .member-bio .member-text-container .member-text .a {
  width: 95%;
  font-size: min(1.5vw, 1.15rem);
  padding: 2% 0 2% 5%;
  margin-left: 5%;
  border-left: 5px solid var(--clr-hover);
}

.member-bio-container:nth-child(2n) {
  flex-direction: row-reverse;
}

@media (max-width: 480px) {
  .member-bio-container {
    flex-direction: column;
  }
  .member-bio-container .member-img-container {
    width: 90%;
    padding: 3% 0 3% 0;
  }
  .member-bio-container .member-bio {
    width: 90%;
  }
  .member-bio-container .member-bio .member-text-container .member-text .q {
    font-size: min(3.5vw, 1.25rem);
  }
  .member-bio-container .member-bio .member-text-container .member-text .a {
    font-size: min(3.5vw, 1.15rem);
  }

  .member-bio-container:nth-child(2n) {
    flex-direction: column;
  }
}
#footer {
  height: 20vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  background: var(--clr-bg);
}
#footer #footer-content-left {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
#footer #footer-content-left .footer-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  background-color: var(--clr-details);
  color: var(--clr-text);
  cursor: pointer;
  border-radius: 50%;
}
#footer #footer-content-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
}
#footer #footer-content-right .footer-credit {
  font-size: min(1.75vw, 1rem);
}

@media (max-width: 991px) {
  #footer {
    height: 15vh;
  }
}
@media (max-width: 480px) {
  #footer {
    height: 10vh;
  }
  #footer #footer-content-left .footer-icon {
    width: 2rem;
    height: 2rem;
  }
}
#navbar {
  width: 100%;
  height: 20vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  z-index: 1;
}
#navbar #nav-left {
  width: 20%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: anotherDanger;
}
#navbar #nav-left #nav-logo-container #nav-logo {
  text-align: center;
}
#navbar #nav-left #nav-logo-container .nav-logo {
  width: 90%;
  padding: -50% 0 -50% 0;
}
#navbar #nav-left #nav-logo-container .mobile-nav-home {
  width: 200%;
  padding-top: 8%;
  padding-left: 5%;
}
#navbar .mobile-nav {
  visibility: hidden;
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translate(5%, -50%);
  width: 3rem;
  height: 3rem;
  padding: 0.25rem;
  background-color: var(--clr-bg);
  color: var(--clr-text);
}
#navbar #nav-right {
  position: relative;
  width: fit-content;
  height: 100%;
  padding: 0 1.6rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  font-size: min(3vw, 1.5rem);
  font-family: "Roboto Condensed";
}
#navbar #nav-right .mobile-nav-close {
  position: absolute;
  top: 5%;
  right: 5%;
  background: #FF007A;
}
#navbar #nav-right .mobile-nav-link {
  padding: 1.5rem;
}
#navbar .nav-link {
  cursor: pointer;
}

.absolute {
  position: absolute;
  border-bottom: none;
}

.relative {
  position: relative;
  border-bottom: 3px solid var(--clr-hover);
}

@media (max-width: 1157px) {
  #navbar #nav-right {
    font-size: min(3vw, 1.2rem);
  }
}
@media (max-width: 991px) {
  #navbar {
    height: 15vh;
  }
  #navbar .mobile-nav {
    visibility: visible;
  }
  #navbar #nav-right {
    visibility: hidden;
    opacity: 0;
    position: absolute;
    right: 0;
    top: 15vh;
    height: 0;
    width: 50%;
    background: var(--clr-bg);
    border-left: 3px solid var(--clr-hover);
    border-bottom: 3px solid var(--clr-hover);
    flex-direction: column;
    z-index: 3;
  }

  .absolute {
    position: relative;
    border-bottom: 3px solid var(--clr-hover);
  }
}
@media (max-width: 550px) {
  #navbar {
    height: 10vh;
  }
  #navbar #nav-right {
    top: 10vh;
  }
}
.btn {
  margin: 20px;
  font-family: anotherDanger;
  font-size: min(4vw, 1.75rem);
}

.custom-btn {
  color: #fff;
  border-radius: 20px;
  padding: 10px 25px;
  background: transparent;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  display: inline-block;
  box-shadow: inset 2px 2px 2px 0 rgba(255, 255, 255, 0.5), 7px 7px 20px 0 rgba(0, 0, 0, 0.1), 4px 4px 5px 0 rgba(0, 0, 0, 0.1);
  outline: none;
}

.btn-11 {
  border: none;
  background: #fb2175;
  background: linear-gradient(0deg, var(--clr-details) 0%, var(--clr-hover) 50%, var(--clr-text) 100%);
  color: #fff;
  overflow: hidden;
}

.btn-11:hover {
  text-decoration: none;
  color: #fff;
}

.btn-11:before {
  position: absolute;
  content: "";
  display: inline-block;
  top: -180px;
  left: 0;
  width: 30px;
  height: 100%;
  background-color: #fff;
}

.btn-11:hover {
  opacity: 0.9;
}

.btn-11:active {
  box-shadow: 4px 4px 6px 0 rgba(255, 255, 255, 0.3), -4px -4px 6px 0 rgba(116, 125, 136, 0.2), inset -4px -4px 6px 0 rgba(255, 255, 255, 0.2), inset 4px 4px 6px 0 rgba(0, 0, 0, 0.2);
}

.back-to-top {
  transform: scale(1.8) rotate(-90deg);
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.25rem;
  background-color: var(--clr-hover);
  color: var(--clr-text);
  position: fixed;
  right: 1.5rem;
  border-radius: 50%;
  top: 5vh;
  cursor: pointer;
  z-index: 5;
}

.back-to-top:hover {
  opacity: 0.9;
}

.visible {
  opacity: 1;
  transition: opacity 0.5s;
}

.hidden {
  opacity: 0;
}

@media (max-width: 820px) {
  .back-to-top {
    visibility: hidden;
  }
}
.video-responsive {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  margin: auto;
}

.video-responsive iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  padding: 1.6rem 0 0;
  position: absolute;
}

#about-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  align-items: center;
}
#about-page #about-img {
  width: 100%;
}
#about-page #about-content-container {
  width: 60%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: column;
  padding: 3rem;
}
#about-page #about-content-container .about-content {
  font-size: min(2vw, 1.15rem);
  padding: 1rem;
  text-align: left;
}
#about-page #about-page-header {
  width: 40%;
  margin: -5% 0 -10% 0;
}

@media (max-width: 915px) {
  #about-page #about-page-header {
    width: 60%;
    margin: -10% 0 -15% 0;
  }
  #about-page #about-content-container {
    width: 80%;
    padding: 1.5rem;
  }
  #about-page #about-content-container .about-content {
    font-size: min(3.5vw, 1.25rem);
  }
}
@media (max-width: 480px) {
  #about-page #about-page-header {
    width: 60%;
    margin: -10% 0 -20% 0;
  }
  #about-page #about-img {
    margin-bottom: 1rem;
  }
}
#band-members-page {
  width: 100%;
}

#events-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#events-page #events-page-header {
  width: 40%;
  margin: -5% 0 -10% 0;
}
#events-page #events-content-container {
  width: 50%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  font-size: min(3vw, 1.15rem);
}
#events-page #events-content-container .events-content:hover .event-link {
  color: #fb2175;
}

@media (max-width: 915px) {
  #events-page #events-page-header {
    width: 60%;
    margin: 0 0 -15% 0;
  }
}
@media (max-width: 480px) {
  #events-page #events-page-header {
    width: 60%;
    margin: 0 0 -20% 0;
  }
}
.modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background: var(--clr-bg);
  transition: opacity 0.4s ease-in-out, visibility 0.4s ease-in-out, transform 0.5s ease-in-out;
  visibility: hidden;
  transform: scale(0.95);
  opacity: 0;
  z-index: 2;
}
.modal #hidden {
  visibility: hidden;
}
.modal .modal-img {
  width: auto;
  max-width: 100%;
  height: auto;
  max-height: 100%;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 1rem;
  margin: 0 auto;
}
.modal #modal-img-credit-container {
  width: 30%;
  display: flex;
  flex-direction: column;
  font-size: min(2vw, 0.75rem);
  justify-content: space-evenly;
  margin-right: 10%;
}
.modal #modal-img-credit-container .credit-link {
  color: var(--clr-hover);
}

.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}

#gallery-prev {
  position: fixed;
  top: 50%;
  left: 1rem;
  width: 3rem;
  height: 3rem;
  padding: 0.25rem;
  background-color: var(--clr-hover);
  color: var(--clr-text);
  cursor: pointer;
  transform: rotateY(180deg);
}

#gallery-close {
  position: fixed;
  top: 1rem;
  right: 1rem;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  background-color: var(--clr-details);
  color: var(--clr-text);
  cursor: pointer;
}

#gallery-next {
  position: fixed;
  top: 50%;
  right: 1rem;
  width: 3rem;
  height: 3rem;
  padding: 0.25rem;
  background-color: var(--clr-hover);
  color: var(--clr-text);
  cursor: pointer;
}

#gallery-page {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  padding: 1rem;
}
#gallery-page .gallery-img {
  width: 100%;
  margin-bottom: 1rem;
  transition: all 350ms ease;
  -webkit-transition: all 350ms ease;
  cursor: pointer;
}
#gallery-page .gallery-img:hover {
  filter: opacity(0.8);
  transform: scale(1.025);
}

@media (max-width: 991px) {
  #gallery-page {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  #gallery-prev {
    display: none;
  }

  #gallery-next {
    display: none;
  }
}
@media (max-width: 820px) {
  .modal {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-y: scroll;
  }
  .modal #modal-img-credit-container {
    width: auto;
    margin-right: 0;
    padding: 1rem 0 2rem 0;
  }
}
@media (max-width: 480px) {
  #gallery-page {
    -webkit-column-count: 1;
    -moz-column-count: 1;
    column-count: 1;
  }
}
#home-page {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}
#home-page #home-img-container {
  position: relative;
  width: 100%;
  height: 100%;
}
#home-page #home-img-container #home-img {
  width: 100%;
}
#home-page #home-img-container #slogan-img {
  position: absolute;
  width: 40%;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}
#home-page #home-content {
  position: relative;
  height: 40vh;
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  text-align: center;
  align-items: center;
  background: var(--clr-bg);
  margin-top: 5vh;
}
#home-page #home-content #home-title {
  font-size: min(1.5vw, 1.5rem);
}
#home-page #home-content #home-text {
  font-size: min(1.5vw, 1.5rem);
}

@media (max-width: 991px) {
  #home-page #home-content {
    width: 70%;
  }
  #home-page #home-content #home-title {
    font-size: min(2.5vw, 1.5rem);
  }
  #home-page #home-content #home-text {
    font-size: min(2.5vw, 1.5rem);
  }
}
@media (max-width: 450px) {
  #home-page #home-img-container {
    margin-bottom: -40%;
  }
  #home-page #home-img-container #slogan-img {
    width: 70%;
    top: 70%;
    right: 50%;
    transform: translate(50%, -70%);
  }
  #home-page #home-content {
    height: 35vh;
    width: 100%;
    padding-top: 5vh;
  }
  #home-page #home-content #home-title {
    font-size: min(3.5vw, 1.5rem);
    width: 70%;
  }
  #home-page #home-content #home-text {
    font-size: min(3.5vw, 1.5rem);
    width: 70%;
  }
}
#contact-page {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
#contact-page #contact-header-container {
  width: 40%;
  margin: -5% 0 -10% 0;
}
#contact-page #contact-header-container .contact-page-header {
  width: 100%;
}
#contact-page #contact-buttons-container {
  width: 70%;
  margin: 5vh 0 5vh 0;
  height: 65vh;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

@media (max-width: 915px) {
  #contact-page #contact-header-container {
    width: 60%;
  }
  #contact-page #contact-header-container .contact-page-header {
    margin: 0 0 -15% 0;
  }
  #contact-page #contact-buttons-container {
    height: 60vh;
    flex-direction: column;
  }
}
@media (max-width: 480px) {
  #contact-page #contact-header-container {
    width: 60%;
  }
  #contact-page #contact-header-container .contact-page-header {
    margin: 0 0 -20% 0;
  }
  #contact-page #contact-buttons-container {
    height: 50vh;
    margin: 15vh 0 15vh 0;
    flex-direction: column;
  }
}
#listen-page-banner-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  height: 22.5vw;
}

#listen-page {
  padding-bottom: 2rem;
}
@media (max-width: 550px) {
  #listen-page {
    padding-bottom: 20vw;
  }
}
@media (max-width: 400px) {
  #listen-page {
    padding-bottom: 35vw;
  }
}
@media (max-width: 480px) {
  #listen-page {
    padding-bottom: 50vw;
  }
}

#listen-page-banner {
  width: 47.5%;
}

@font-face {
  font-family: anotherDanger;
  src: url(AnotherDangerDemo.woff);
}

