@use "Default";
@use "../Components";
@use "../Pages";
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed:ital@1&display=swap');

@font-face {
    font-family: anotherDanger;
    src: url(AnotherDangerDemo.woff);
}

