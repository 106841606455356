#contact-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;

    #contact-header-container {
        width: 40%;
        margin: -5% 0 -10% 0;

        .contact-page-header {
            width: 100%;
        }
    }

    #contact-buttons-container {
        width: 70%;
        margin: 5vh 0 5vh 0;
        height: 65vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
    }
}

@media (max-width: 915px) {
    #contact-page {

        #contact-header-container {
            width: 60%;

            .contact-page-header {
                margin: 0 0 -15% 0;
            }
        }

        #contact-buttons-container {
            height: 60vh;
            flex-direction: column;
        }
    }
}

@media (max-width: 480px) {
    #contact-page {

        #contact-header-container {
            width: 60%;

            .contact-page-header {
                margin: 0 0 -20% 0;
            }
        }

        #contact-buttons-container {
            height: 50vh;
            margin: 15vh 0 15vh 0;
            flex-direction: column;
        }
    }
}