.back-to-top {
    transform: scale(1.8) rotate(-90deg);
    width: 2.5rem;
    height: 2.5rem;
    padding: .25rem;
    background-color: var(--clr-hover);
    color: var(--clr-text);
    position: fixed;
    right: 1.5rem;
    border-radius: 50%;
    top: 5vh;
    cursor: pointer;
    z-index: 5;
}

.back-to-top:hover {
    opacity: 0.9;
}

.visible {
    opacity: 1;
    transition: opacity 0.5s;
}

.hidden {
    opacity: 0;
}

@media (max-width: 820px) {
    .back-to-top {
        visibility: hidden;
    }
}