.member-bio-container {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    padding: 2% 0 2% 0;

    .member-img-container {
        position: relative;
        width: 40%;
        height: 40%;
        padding: 1rem;

        .member-img {
            width: 100%;
            height: 100%;
            filter: contrast(110%);
        }

        .member-signature {
            width: 70%;
            position: absolute;
            left: 10%;
            bottom: 12.5%;
            opacity: 0;
            pointer-events: none;
            filter: contrast(150%);
            border-radius: 50%;
        }
    }

    .member-bio {
        position: relative;
        width: 45%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: center;

        .member-name {
            width: 90%;
            margin: -20% 0 -20% 0;        }

        .member-text-container {
            height: 90%;

            .member-text {
                position: relative;
                height: 100%;
                display: flex;
                justify-content: space-evenly;
                flex-direction: column;

                .q {
                    font-size: min(1.5vw, 1.25rem);
                    color: var(--clr-q);
                    padding: 2% 0 2% 0;
                }

                .a {
                    width: 95%;
                    font-size: min(1.5vw, 1.15rem);
                    //font-style: italic;
                    padding: 2% 0 2% 5%;
                    margin-left: 5%;
                    border-left: 5px solid var(--clr-hover);
                }
            }
        }
    }
}

.member-bio-container:nth-child(2n) {
    flex-direction: row-reverse;
    //background: var(--clr-logoAccent);
}

@media (max-width: 480px){
    .member-bio-container {
        flex-direction: column;

        .member-img-container {
            width: 90%;
            padding: 3% 0 3% 0;
        }

        .member-bio {
            width: 90%;

            .member-text-container {

                .member-text {

                    .q {
                        font-size: min(3.5vw, 1.25rem);
                    }

                    .a {
                        font-size: min(3.5vw, 1.15rem);
                    }
                }
            }
        }
    }
    .member-bio-container:nth-child(2n) {
        flex-direction: column;
        //background: var(--clr-logoAccent);
    }
}