#events-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    #events-page-header {
        width: 40%;
        margin: -5% 0 -10% 0;
    }

    #events-content-container {
        width: 50%;
        height: 70vh;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        font-size: min(3vw, 1.15rem);

        .events-content {
            &:hover {
                .event-link {
                    color: #fb2175;
                }
            }
        }
    }
}

@media (max-width: 915px) {
    #events-page {

        #events-page-header {
            width: 60%;
            margin: 0 0 -15% 0;
        }
    }
}

@media (max-width: 480px){
    #events-page {

        #events-page-header {
            width: 60%;
            margin: 0 0 -20% 0;
        }

    }
}