#about-page {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-evenly;
    align-items: center;

    #about-img {
        width: 100%;
    }

    #about-content-container {
        width: 60%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        padding: 3rem;


        .about-content {
            font-size: min(2vw, 1.15rem);
            padding: 1rem;
            text-align: left;
        }
    }

    #about-page-header {
        width: 40%;
        margin: -5% 0 -10% 0;
    }
}

@media (max-width: 915px){
    #about-page {

        #about-page-header {
            width: 60%;
            margin: -10% 0 -15% 0;
        }

        #about-content-container{
            width: 80%;
            padding: 1.5rem;

            .about-content {
                font-size: min(3.5vw, 1.25rem);
            }
        }
    }
}

@media (max-width: 480px){
    #about-page {

        #about-page-header {
            width: 60%;
            margin: -10% 0 -20% 0;
        }

        #about-img {
            margin-bottom: 1rem;
        }
    }
}