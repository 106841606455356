.btn {
    margin: 20px;
    font-family: anotherDanger;
    font-size: min(4vw, 1.75rem);
}
.custom-btn {
    color: #fff;
    border-radius: 20px;
    padding: 10px 25px;
    background: transparent;
    cursor: pointer;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow:inset 2px 2px 2px 0 rgba(255,255,255,.5),
    7px 7px 20px 0 rgba(0,0,0,.1),
    4px 4px 5px 0 rgba(0,0,0,.1);
    outline: none;
}

.btn-11 {
    border: none;
    background: rgb(251,33,117);
    background: linear-gradient(0deg, var(--clr-details) 0%, var(--clr-hover) 50%, var(--clr-text) 100%);
    color: #fff;
    overflow: hidden;
}
.btn-11:hover {
    text-decoration: none;
    color: #fff;
}
.btn-11:before {
    position: absolute;
    content: '';
    display: inline-block;
    top: -180px;
    left: 0;
    width: 30px;
    height: 100%;
    background-color: #fff;
}
.btn-11:hover{
    opacity: .9;
}
.btn-11:active{
    box-shadow:  4px 4px 6px 0 rgba(255,255,255,.3),
    -4px -4px 6px 0 rgba(116, 125, 136, .2),
    inset -4px -4px 6px 0 rgba(255,255,255,.2),
    inset 4px 4px 6px 0 rgba(0, 0, 0, .2);
}